import { Component, ɵConsole } from '@angular/core';
import dataRegiones from 'src/assets/data/regiones.json';
import dataXregion from 'src/assets/data/consolidado.json';



import { ProviderService } from 'src/app/services/provider.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  regiones: [] = dataRegiones;
  data: [] = dataXregion;
  dataxEnviar = [];

  title = 'observatorio';
  titleReg = '';
  infoFromDetils;
  amountInit = "";

  constructor(private providerService: ProviderService) {

  }

  ngOnInit() {
    //console.log(this.data);
    window["data"] = this.data;
  }

  slideDash(panel, value, section) {
    let urlImage = value.src;
    let typeImage = urlImage.split("/").pop();
    if (typeImage === "grafica.png") {
      value.src = "assets/images/graficas-arrow.png";
      panel.style.display = "block";
      section.style.top = "20vh";
    } else {
      value.src = "assets/images/grafica.png";
      panel.style.display = "none";
      section.style.top = "100vh";
    }
    eval('$(".navbar-collapse").collapse(\'hide\')');
  }

  handleClick(event, id, texto) {
    eval('$(".navbar-collapse").collapse(\'hide\')');

    this.amountInit = this.getAmountInit(id);
    let tooltip = document.getElementById("tooltip");
    let caja = document.getElementById("cajatol");
    let arrow = document.getElementById("arrowbtn");
    if (this.amountInit != "0") {
      var evt = event;
      let anchoTotal = screen.width;
      this.titleReg = texto;
      tooltip.style.display = "block";
      let left = evt.pageX - 100;
      let top = evt.pageY - 130;
      let arrowpx = 85;
      if (left < 0) {
        left = 10;
        arrowpx = evt.pageX - 24;
      }
      if ((anchoTotal - 100) < evt.pageX) {
        var result = (anchoTotal - 100) - evt.pageX;
        left = left + (result - 10);
        arrowpx = 194 - (anchoTotal - evt.pageX);
      }
      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
      arrow.style.left = `${arrowpx}px`;
    } else {
      tooltip.style.display = "none";
    }

  }

  verRegion(value, detalle) {
    let tooltip = document.getElementById("tooltip");
    tooltip.style.display = "none";
    detalle.style.display = "block";
    this.dataxEnviar = this.data.filter(element => element['region'] === value);
    this.providerService.enviar(this.dataxEnviar);
    eval('$(".navbar-collapse").collapse(\'hide\')');
  }

  closeModal(section) {
    section.style.display = "none";
  }
  openModal(section) {
    section.style.display = "flex";
  }

  mouse(event, id, texto) {
    //console.log(texto);
    this.amountInit = this.getAmountInit(id);
    let tooltip = document.getElementById("tooltip2");
    let arrow = document.getElementById("arrowbtn2");

    if (this.amountInit != "0") {
      var evt = event;
      let anchoTotal = screen.width;
      this.titleReg = texto;
      tooltip.style.display = "block";
      let left = evt.pageX - 100;
      let top = evt.pageY - 130;
      let arrowpx = 85;
      if (left < 0) {
        left = 10;
        arrowpx = evt.pageX - 24;
      }
      if ((anchoTotal - 100) < evt.pageX) {
        var result = (anchoTotal - 100) - evt.pageX;
        left = left + (result - 10);
        arrowpx = 194 - (anchoTotal - evt.pageX);
      }
      tooltip.style.left = `${left}px`;
      tooltip.style.top = `${top}px`;
      arrow.style.left = `${arrowpx}px`;
    } else {
      tooltip.style.display = "none";
    }

  }

  otherClick(detalle, id, texto) {
    let tooltip = document.getElementById("tooltip2");

    if (this.amountInit === "0") {

    } else {
      tooltip.style.display = "none";
      detalle.style.display = "block";
      this.dataxEnviar = this.data.filter(element => element['region'] === texto);
      this.providerService.enviar(this.dataxEnviar);
    }


  }

  infoToPopup(mensaje) {
    this.infoFromDetils = mensaje;
    let popup = document.getElementById('secPop');
    popup.style.display = "flex";

  }

  closeToltip(tooltip) {
    tooltip.style.display = "none";
  }

  getAmountInit(codigo: String) {
    var resul = this.regiones.find(region => region['codeRegion'] === codigo)
    return resul['amountInit'];
  }

  closeTooltipOne(event, tool) {
    if (event.path[0]['nodeName'] != "path") {
      tool.style.display = "none";
    }
  }
}
