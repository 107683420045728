import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ProviderService {

  private data = new BehaviorSubject<Array<any>>([]);

  data$ = this.data.asObservable();

  enviar(data){
    this.data.next(data);
  }

  constructor() { }
}
