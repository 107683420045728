import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import dataXregion from 'src/assets/data/consolidado.json';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {

  urlswitch = "assets/images/switchon.png";

  categorias: Food[] = [];
  anio: Food[] = [];
  temas: Food[] = [];

  data: any[] = dataXregion.sort((a, b) => b.anio - a.anio);
  dataFiltrada: any[] = this.data;
  dataInicio: any[] = [];
  
  searchText: String = '';
  selectedCategoria: String = '';
  selectedAnio: String = '';
  selectedTema: String = '';
  @Output() dataFromDetails = new EventEmitter<string>();

  constructor() { 
      
  }

  ngOnInit() {
      this.getCategorias(window["data"]);
      this.getTemas(window["data"]);
      this.getAnio(window["data"]);
  }


  getCategorias(data) {
    if(!data) return data;

    this.categorias = []
    data.forEach(element => {
      this.categorias.push({ 'value': element.categoria, 'viewValue': element.categoria })
    });
    var hash = {};
    this.categorias = this.categorias.filter(function (current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }
  getAnio(data) {
    if(!data) return data;

    this.anio = []
    data.forEach(element => {
      this.anio.push({ 'value': element.anio, 'viewValue': element.anio })
    });
    var hash = {};
    this.anio = this.anio.filter(function (current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }
  getTemas(data) {
    if(!data) return data;

    this.temas = []
    let elementos = [];
    data.forEach(element => {
      var cadenas = element.area.split(',');
      cadenas.forEach(cadena => {
        elementos.push({ 'value': cadena, 'viewValue': cadena })
      });
    });
    console.log(elementos);
    var hash = {};
    this.temas = elementos.filter(function(current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }

  onchange(event) {
    let dataTemporal = [];
    /**switch */
    if(this.urlswitch === "assets/images/switchon.png"){
      dataTemporal = this.data;
    }else{
      dataTemporal = this.data.filter(element => element.ganador != "P");
    }
    /**category */
    if(this.selectedCategoria != "" && this.selectedCategoria != "todos"){
      dataTemporal = dataTemporal.filter(c => c.categoria.toLowerCase().includes(this.selectedCategoria.toLowerCase()));
    }else{
      dataTemporal = dataTemporal;
    }
    /**anio */
    if(this.selectedAnio != "" && this.selectedAnio != "todos"){
      dataTemporal = dataTemporal.filter(c => c.anio == this.selectedAnio);
    }else{
      dataTemporal = dataTemporal;
    }
    /**tema */
    if(this.selectedTema != "" && this.selectedTema != "todos"){
      dataTemporal = dataTemporal.filter(c => c.area.toLowerCase().includes(this.selectedTema.toLowerCase()));
    }else{
      dataTemporal = dataTemporal;
    }

    this.dataInicio = dataTemporal;
    console.log(this.dataInicio.length)
  }

  onKey(e) {
    if(this.searchText.length>0){
      this.dataInicio = this.dataFiltrada.filter(element => element.proyecto.toLowerCase().includes(this.searchText.toLowerCase()));
    }else{
      this.dataInicio = [];
    }
  }

  enviar(mensaje) {
    this.dataFromDetails.emit(mensaje);
  }

  switch(eve) {
    if (this.urlswitch == "assets/images/switchon.png") {
      this.urlswitch = "assets/images/switchoff.png";
      this.onchange(eve);
    } else {
      this.urlswitch = "assets/images/switchon.png";
      this.onchange(eve);
    }

  }
}
