import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { ProviderService } from 'src/app/services/provider.service';

export interface Food {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.scss']
})
export class DetalleComponent implements OnInit {

  urlswitch = "assets/images/switchon.png";
  categorias: Food[] = [];
  anio: Food[] = [];
  temas: Food[] = [];
  nombreRegion = '';
  imgRegion = {};
  searchText = "";

  dataRegion = [];
  dataRegionFiltrada = [];

  selectedCategoria: String = '';
  selectedAnio: String = '';
  selectedTema: String = '';

  dataRegionInicio: any[] = [];
  activarResultado = false;

  @Output() messageFromDetails = new EventEmitter<string>();
  @Input() dataxrecibir: [];
  constructor(private providerService: ProviderService) {
    this.providerService.data$.pipe().subscribe(res => {
      this.dataRegion = res.sort((a, b) => b.anio - a.anio);
      this.dataRegionFiltrada = this.dataRegion;
      if (res.length > 0) {
        this.nombreRegion = res[0]['region'];
        let replaceRegion = this.nombreRegion.replace(' ', '-');
        let normalizeRegion = replaceRegion.normalize('NFD').replace(/([aeio])\u0301|(u)[\u0301\u0308]/gi,"$1$2").normalize();
        this.imgRegion = {
          "background-image": `url(./assets/images/regiones/${normalizeRegion.toLowerCase()}.jpg)`
        };
      }

      /*this.getCategorias(res);
      this.getTemas(res);
      this.getAnio(res);*/
      //console.log(window["data"]);
      this.getCategorias(window["data"]);
      this.getTemas(window["data"]);
      this.getAnio(window["data"]);


      this.dataRegionInicio = [];
      this.activarResultado = false;
    })
  }

  ngOnInit() {
    
  }

  regresar() {
    let detalle = document.getElementById('contDetalle');
    this.selectedTema = '';
    this.selectedCategoria = '';
    this.selectedAnio = '';
    detalle.style.display = "none";
    this.searchText="";
  }

  openModal(mensaje) {
    this.messageFromDetails.emit(mensaje);
  }

  getCategorias(data) {
    if(!data) return data;

    this.categorias = []
    data.forEach(element => {
      this.categorias.push({ 'value': element.categoria, 'viewValue': element.categoria })
    });
    var hash = {};
    this.categorias = this.categorias.filter(function (current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }
  getAnio(data) {
    if(!data) return data;

    this.anio = []
    data.forEach(element => {
      this.anio.push({ 'value': element.anio, 'viewValue': element.anio })
    });
    var hash = {};
    this.anio = this.anio.filter(function (current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }
  getTemas(data) {
    if(!data) return data;

    this.temas = []
    let elementos = [];
    data.forEach(element => {
      var cadenas = element.area.split(',');
      cadenas.forEach(cadena => {
        elementos.push({ 'value': cadena, 'viewValue': cadena })
      });
    });
    var hash = {};
    this.temas = elementos.filter(function(current) {
      var exists = !hash[current.value] || false;
      hash[current.value] = true;
      return exists;
    });
  }

  /** changes */
  onchange(event) {
    let dataTemporal = [];
    /**switch */
    if(this.urlswitch === "assets/images/switchon.png"){
      dataTemporal = this.dataRegion;
    }else{
      dataTemporal = this.dataRegion.filter(element => element.ganador != "P");
    }
    /**category */
    if(this.selectedCategoria != "" && this.selectedCategoria != "todos"){
      dataTemporal = dataTemporal.filter(c => c.categoria.toLowerCase().includes(this.selectedCategoria.toLowerCase()));
    }else{
      dataTemporal = dataTemporal;
    }
    /**anio */
    if(this.selectedAnio != "" && this.selectedAnio != "todos"){
      dataTemporal = dataTemporal.filter(c => c.anio == this.selectedAnio);
    }else{
      dataTemporal = dataTemporal;
    }
    /**tema */
    if(this.selectedTema != "" && this.selectedTema != "todos"){
      dataTemporal = dataTemporal.filter(c => c.area.toLowerCase().includes(this.selectedTema.toLowerCase()));
    }else{
      dataTemporal = dataTemporal;
    }

    this.dataRegionInicio = dataTemporal;
    this.activarResultado = true;
    console.log(this.dataRegionInicio.length)
  }

  onKey(event) {
    if(this.searchText.length>0){
      this.dataRegionInicio = this.dataRegionFiltrada.filter(element => element.proyecto.toLowerCase().includes(this.searchText.toLowerCase()))
      this.activarResultado = true;
    }else{
      this.dataRegionInicio = this.dataRegionFiltrada.filter(element => element.proyecto.toLowerCase().includes("&%$%"))
      this.activarResultado = false;
    }    
  }

  switch(eve) {
    if (this.urlswitch == "assets/images/switchon.png") {
      this.urlswitch = "assets/images/switchoff.png";
      this.onchange(eve);
    } else {
      this.urlswitch = "assets/images/switchon.png";
      this.onchange(eve);
    }
  }

}
